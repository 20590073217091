import PaymentService from '../../services/payment';

export default {
	namespaced: true,
	state: {
		selectedPaymentMethod: null,
		session: null
	},
	getters: {
		getSelectedPaymentMethod: state => state.selectedPaymentMethod,
		getSession: state => state.session
	},
	mutations: {
		setSelectedPaymentMethod(state, value) {
			state.selectedPaymentMethod = value
		},
		setSession(state, value) {
			state.session = value
		}
	},
	actions: {
		async createSession({commit}, session) {
			const res = await PaymentService.CreateSession(session);
			if (res) {
			  commit('setSession', res)
			  return true;
			} else {
			  return false;
			}
		  },

		  async cancelSession({}, sessionId) {
			const res = await PaymentService.CancelSession(sessionId);
			return res;
		  },
	}
};
