<template>
  <v-layout row justify-center>
    <div class="profile pb-5 pt-2">
      <v-sheet>
        <div
          class="profile__container primary-font"
          style="color: var(--v-primary-base)"
        >
          <v-row :justify="hide ? 'center' : 'space-between'">
            <v-col
              v-if="!isNoShowPayment()"
              cols="12"
              class="profile__container--breadcrumbs my-5"
            >
              <breadcrumbs
                :breadcrumbs="breadcrumbs"
                :layout="layout"
              ></breadcrumbs>
            </v-col>
            <transition mode="out-in" name="fade-transition">
              <v-col
                cols="6"
                md="4"
                lg="4"
                v-if="$vuetify.breakpoint.mdAndUp && !hide"
                class="profile__container--navigation"
              >
                <user-menu
                  :activeItem="currentViewTitle"
                  :user="user"
                  :layout="layout"
                  @logout="$emit('logout')"
                  :isProfile="isProfile"
                ></user-menu>
              </v-col>
            </transition>
            <v-col
              cols="12"
              sm="12"
              md="8"
              lg="8"
              class="profile__container--content"
              :class="[
                !hide ? 'pt-10' : 'pt-5',
                isNoShowPayment() ? 'px-1' : 'px-5',
              ]"
            >
              <transition mode="out-in" name="fade-transition">
                <component
                  v-bind:is="currentView"
                  v-bind="currentProps"
                  @updateUser="(user) => $emit('updateUser', user)"
                  @logout="$emit('logout')"
                />
              </transition>
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </div>
  </v-layout>
</template>
<script>
import {
  OrderDetails,
  ProductOrderDetails,
  ProgramInfo,
  PointsHistory,
  TermsForUse,
  FavouriteEmployees,
  FavouriteTreatments,
  FavouriteProducts,
  TheConsents,
  OrderHistory,
  MembershipProgram,
  MySalons,
  JoinProgram,
  Giftcards,
  PaymentMethods,
  UserProfile,
  NoShow,
  NoShowDetails,
  Payment,
  PaymentConfirmation,
  Breadcrumbs,
  GiftcardDetails,
} from "@fixit/fixit-no-header/src/components/Profile/components";
import { UserMenu } from "@fixit/fixit-no-header/src/components/Common/CommonComponents";

export default {
  components: {
    Breadcrumbs,
    UserProfile,
    UserMenu,
    OrderDetails,
    ProductOrderDetails,
    ProgramInfo,
    PointsHistory,
    TermsForUse,
    FavouriteEmployees,
    FavouriteTreatments,
    FavouriteProducts,
    TheConsents,
    OrderHistory,
    MembershipProgram,
    MySalons,
    JoinProgram,
    Giftcards,
    NoShow,
    NoShowDetails,
    Payment,
    PaymentMethods,
    PaymentConfirmation,
    GiftcardDetails,
  },
  data: function () {
    return {
      currentView: UserProfile,
      currentViewTitle: "Profil",
      previousViewTitle: "",
      order: null,
      program: null,
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: null,
    },
    layout: {
      required: false,
      default: "portal",
      type: String,
    },
    useAxios: Boolean,
    isProfile: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    currentProps() {
      switch (this.currentViewTitle) {
        case "Profil":
          return { user: this.user, layout: this.layout };
        case "Mine salonger":
          return { layout: this.layout };
        case "Favorittansatte":
          return { layout: this.layout };
        case "Favorittbehandlinger":
          return { layout: this.layout };
        case "Favorittprodukter":
          return { layout: this.layout };
        case "Personvern og samtykker":
          return { user: this.user, layout: this.layout };
        case "Mine besøk og bestillinger":
          return { layout: this.layout };
        case "Ordredetaljer":
          return { layout: this.layout };
        case "ProduktOrdredetaljer":
          return { user: this.user, layout: this.layout };
        case "Fordelsprogram":
          return { layout: this.layout };
        case "Info fordelsprogram":
          return { program: this.program, layout: this.layout };
        case "Poenghistorikk":
          return { program: this.program, layout: this.layout };
        case "Vilkår for bruk":
          return { program: this.program, layout: this.layout };
        case "Bli med i fordelsprogram":
          return { program: this.program, layout: this.layout };
        case "PoengOrdredetaljer":
          return { order: this.order, layout: this.layout };
        case "Betalingsmetoder":
          return { user: this.user, layout: this.layout };
        case "Ikke møtt":
          return { user: this.user };
        case "Ikke møtt detaljer":
          return { user: this.user };
        case "Sen kansellering detaljer":
          return { user: this.user };
        case "Valg av betalingsmetode":
          return { user: this.user };
        case "Valg av betalingsmetode for kansellering":
          return { user: this.user };
        case "Betalingsbekreftelse":
          return { user: this.user };
        case "Betalingsbekreftelse for kansellering":
          return { user: this.user };
        case "Mine gavekort":
          return { layout: this.layout };
        case "Bruk gavekort":
          return { layout: this.layout };
      }
    },
    pages() {
      return [
        { title: "Mine salonger", icon: "fxt-salon", component: MySalons },
        {
          title: "Favorittansatte",
          icon: "fxt-heart",
          component: FavouriteEmployees,
        },
        {
          title: "Favorittbehandlinger",
          icon: "fxt-heart",
          component: FavouriteTreatments,
        },
        {
          title: "Favorittprodukter",
          icon: "fxt-heart",
          component: FavouriteProducts,
        },
        {
          title: "Mine besøk og bestillinger",
          icon: "fxt-cart",
          component: OrderHistory,
        },
        { title: "Mine gavekort", icon: "fxt-gift", component: Giftcards },
        { title: "Bruk gavekort", icon: "", component: GiftcardDetails },
        {
          title: "Betalingsmetoder",
          icon: "fxt-credit-card-checked",
          component: PaymentMethods,
        },
        { title: "Personvern og samtykker", icon: "", component: TheConsents },
        { title: "Profil", icon: "", component: UserProfile },
        { title: "Ordredetaljer", icon: "", component: OrderDetails },
        {
          title: "ProduktOrdredetaljer",
          icon: "",
          component: ProductOrderDetails,
        },
        { title: "Fordelsprogram", icon: "", component: MembershipProgram },
        { title: "Info fordelsprogram", icon: "", component: ProgramInfo },
        { title: "Bli med i fordelsprogram", icon: "", component: JoinProgram },
        { title: "Poenghistorikk", icon: "", component: PointsHistory },
        { title: "Vilkår for bruk", icon: "", component: TermsForUse },
        { title: "PoengOrdredetaljer", icon: "", component: OrderDetails },
        { title: "Ikke møtt", icon: "", component: NoShow },
        { title: "Ikke møtt detaljer", icon: "", component: NoShowDetails },
        {
          title: "Sen kansellering detaljer",
          icon: "",
          component: NoShowDetails,
        },
        { title: "Valg av betalingsmetode", icon: "", component: Payment },
        {
          title: "Betalingsbekreftelse",
          icon: "",
          component: PaymentConfirmation,
        },
        {
          title: "Valg av betalingsmetode for kansellering",
          icon: "",
          component: Payment,
        },
        {
          title: "Betalingsbekreftelse for kansellering",
          icon: "",
          component: PaymentConfirmation,
        },
      ];
    },
    breadcrumbs() {
      var baseCrumbs = [
        { name: "Fixit", disabled: false },
        { name: "Mine sider", disabled: false },
      ];
      switch (this.currentViewTitle) {
        case "Ordredetaljer":
          if (this.previousViewTitle == "Poenghistorikk") {
            return baseCrumbs.concat([
              {
                name: "Fordelsprogram",
                disabled: false,
                component: MembershipProgram,
              },
              ...(this.program
                ? [
                    {
                      name: "Poenghistorikk",
                      id: this.program.customerProgramId,
                      program: this.program,
                      disabled: false,
                      component: PointsHistory,
                    },
                  ]
                : []),
              {
                name: this.order
                  ? this.formatDate(this.order.appointmentDate)
                  : "Ordre",
                disabled: true,
              },
            ]);
          } else {
            return baseCrumbs.concat([
              {
                name: "Mine besøk og bestillinger",
                disabled: false,
                component: OrderHistory,
              },
              {
                name: this.order
                  ? this.formatDate(this.order.appointmentDate)
                  : "Ordre",
                disabled: true,
              },
            ]);
          }

        case "ProduktOrdredetaljer":
          if (this.previousViewTitle == "Poenghistorikk") {
            return baseCrumbs.concat([
              {
                name: "Fordelsprogram",
                disabled: false,
                component: MembershipProgram,
              },
              ...(this.program
                ? [
                    {
                      name: "Poenghistorikk",
                      id: this.program.customerProgramId,
                      program: this.program,
                      disabled: false,
                      component: PointsHistory,
                    },
                  ]
                : []),
              {
                name: this.order
                  ? this.formatDate(this.order.appointmentDate)
                  : "Ordre",
                disabled: true,
              },
            ]);
          } else {
            return baseCrumbs.concat([
              {
                name: "Mine besøk og bestillinger",
                disabled: false,
                component: OrderHistory,
              },
              {
                name: this.order
                  ? this.order.date
                    ? this.formatDate(this.order.date)
                    : this.formatDate(this.order.createdTime)
                  : "Ordre",
                disabled: true,
              },
            ]);
          }
        case "PoengOrdredetaljer":
          return baseCrumbs.concat([
            {
              name: "Fordelsprogram",
              disabled: false,
              component: MembershipProgram,
            },
            ...(this.program
              ? [
                  {
                    name: this.program.programName,
                    id: this.program.customerProgramId,
                    program: this.program,
                    disabled: false,
                    component: ProgramInfo,
                    componentName: "Info fordelsprogram",
                  },
                ]
              : []),
            ...(this.program
              ? [
                  {
                    name: "Poenghistorikk",
                    id: this.program.customerProgramId,
                    program: this.program,
                    disabled: false,
                    component: PointsHistory,
                  },
                ]
              : []),
            {
              name: this.order
                ? this.formatDate(this.order.appointmentDate)
                : "Ordre",
              disabled: true,
            },
          ]);
        case "Info fordelsprogram":
          return baseCrumbs.concat([
            {
              name: "Fordelsprogram",
              disabled: false,
              component: MembershipProgram,
            },
            {
              name: this.program ? this.program.programName : "Program",
              disabled: true,
            },
          ]);
        case "Bli med i fordelsprogram":
          return baseCrumbs.concat([
            {
              name: "Fordelsprogram",
              disabled: false,
              component: MembershipProgram,
            },
            {
              name: this.program ? this.program.programName : "Program",
              disabled: true,
            },
          ]);
        case "Poenghistorikk":
          return baseCrumbs.concat([
            { name: "Fordelsprogram", component: MembershipProgram },
            ...(this.program
              ? [
                  {
                    name: this.program.programName,
                    id: this.program.customerProgramId,
                    program: this.program,
                    disabled: false,
                    component: ProgramInfo,
                    componentName: "Info fordelsprogram",
                  },
                ]
              : []),
            { name: this.currentViewTitle, disabled: true },
          ]);
        case "Vilkår for bruk":
          return baseCrumbs.concat([
            {
              name: "Fordelsprogram",
              disabled: false,
              component: MembershipProgram,
            },
            ...(this.program
              ? [
                  {
                    name: this.program.programName,
                    id: this.program.customerProgramId,
                    program: this.program,
                    disabled: false,
                    component: ProgramInfo,
                    componentName: this.program.isMember
                      ? "Info fordelsprogram"
                      : "Bli med i fordelsprogram",
                  },
                ]
              : []), //TODO: fix componentName
            { name: this.currentViewTitle, disabled: true },
          ]);
        case "Ikke møtt":
          return baseCrumbs.concat([{ name: "Ikke møtt", component: NoShow }]);
        case "Ikke møtt detaljer":
          return baseCrumbs.concat([
            { name: "Ikke møtt", component: NoShow },
            { name: "Detaljer", disabled: true },
          ]);
        case "Bruk gavekort":
          return baseCrumbs.concat([
            { name: "Mine gavekort", component: Giftcards },
            { name: this.currentViewTitle, disabled: true },
          ]);
        default:
          return baseCrumbs.concat([
            { name: this.currentViewTitle, disabled: true },
          ]);
      }
    },
    hide() {
      return [
        "Bruk gavekort",
        "Ordredetaljer",
        "ProduktOrdredetaljer",
        "PoengOrdredetaljer",
        "Info fordelsprogram",
        "Bli med i fordelsprogram",
        "Poenghistorikk",
        "Vilkår for bruk",
        "Ikke møtt detaljer",
        "Valg av betalingsmetode",
        "Valg av betalingsmetode for kansellering",
        "Betalingsbekreftelse",
        "Betalingsbekreftelse for kansellering",
      ].includes(this.currentViewTitle);
    },
  },
  methods: {
    async setComponent(route) {
      var page = this.pages.find((x) => x.title == route.name);
      if (page != null) {
        this.previousViewTitle = this.currentViewTitle;
        this.currentView = page.component;
        this.currentViewTitle = page.title;
        if (route.params.order) {
          this.order = route.params.order;
        }
        if (route.params.program) {
          this.program = route.params.program;
        }
        if (this.previousViewTitle.includes('betalingsmetode')) {
          await this.$store.dispatch('fairpay/getFairPayAppointmentDetails')
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    formatDate(appointmentDate) {
      return new Date(appointmentDate).toLocaleDateString("no-NO", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    isNoShowPayment() {
      return (
        (this.$route.path.includes("/betaling") ||
          this.$route.path.includes("/bekreftelse")) &&
        (this.$route.path.includes("/ikke-mott") ||
          this.$route.path.includes("/sen-kansellering"))
      );
    },
  },
  watch: {
    async $route(to) {
      await this.setComponent(to);
    },
  },
  async created() {
    await this.setComponent(this.$route);
    document.title = document.title + " - " + this.user.firstName;
  },
};
</script>
