import axios from 'axios';

export default {
	async GetFairpayAppointmentDetails() {
		const result = await axios.post(process.env.VUE_APP_FIXIT_NO_BOOKING_URL + '/api/my-page/fairpay-appointment-details');
		if (result.data.responseCode === 200) {
			return result.data.dto;
		} else return []
	},
	async CompletePayment(data) {
		return await axios.post(process.env.VUE_APP_FIXIT_NO_BOOKING_URL + '/api/fairpay/complete', data);
	},
	async CreateFairPay(data) {
		const result = await axios.post(process.env.VUE_APP_FIXIT_NO_BOOKING_URL + '/api/fairpay/create', data);
		if (result.data.responseCode === 200) {
			return result.data.dto;
		} else return null
	},
	async CompleteAndCancelAppointment(data) {
		return await axios.post(process.env.VUE_APP_FIXIT_NO_BOOKING_URL + `/api/fairpay/complete-and-cancel-appointment/${data.siteId}/${data.id}`, 
		{
			fixitPaySessionId: data.fixitPaySessionId,
			fixitSiteId: data.fixitSiteId,
			timebokBesokId: data.timebokBesokId
		});
	},
	async CancelAppointmentAndCreate(data) {
		const result = await axios.post(process.env.VUE_APP_FIXIT_NO_BOOKING_URL + `/api/fairpay/cancel-appointment-and-create/${data.siteId}/${data.id}`, 
		{
			fixitSiteId: data.fixitSiteId,
			timebokBesokId: data.timebokBesokId
		});
		if (result.data.responseCode === 200) {
			return result.data.dto;
		} else return null
	},
	async DeleteFairPay(data) {
		const result = await axios.post(process.env.VUE_APP_FIXIT_NO_BOOKING_URL + `/api/fairpay/delete/${data.siteId}/${data.id}`);
		if (result.data.responseCode === 200) {
			return result.data.dto;
		} else return false
	}
};
