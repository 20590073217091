import FairpayService from '../../services/fairpay';

export default {
	namespaced: true,
	state: {
		fairPayAppointments: [],
		createdFairpay: null,
	},
	getters: {
		getFairPayAppointmentsCount: state => state.fairPayAppointments.length,
		getFairPayAppointments: state => state.fairPayAppointments,
		getCreatedFairpay: state => state.createdFairPay,
	},
	mutations: {
		setFairPayAppointments(state, value) {
			state.fairPayAppointments = value
		},
		setCreatedFairpay(state, value) {
			state.createdFairPay = value
		}
	},
	actions: {
		async getFairPayAppointmentDetails({commit}) {
			let result = await FairpayService.GetFairpayAppointmentDetails()
			commit('setFairPayAppointments', result)
			return result
		},
		async completePayment({}, data) {
			let result = await FairpayService.CompletePayment(data);
			return result
		},
		async createFairPay({commit}, data) {
			let result = await FairpayService.CreateFairPay(data);
			commit('setCreatedFairpay', result)
			return result
		},
		async completeAndCancelAppointment({}, data) {
			let result = await FairpayService.CompleteAndCancelAppointment(data)
			return result
		},
		async cancelAppointmentAndCreate({commit}, data) {
			let result = await FairpayService.CancelAppointmentAndCreate(data)
			commit('setCreatedFairpay', result)
			return result
		},
		async deleteFairPay({}, data) {
			let result = await FairpayService.DeleteFairPay(data)
			return result
		},
	}
};
