import PaymentService from '../../services/payment';

export default {
	namespaced: true,
	state: {
		selectedPaymentMethod: null,
		session: null,
		paymentTokenId: null,
	},
	getters: {
		getSelectedPaymentMethod: state => state.selectedPaymentMethod,
		getSession: state => state.session,
		getPaymentTokenId: state => state.paymentTokenId
	},
	mutations: {
		setSelectedPaymentMethod(state, value) {
			state.selectedPaymentMethod = value
		},
		setSession(state, value) {
			state.session = value
		},
		setPaymentTokenId(state, value) {
			state.paymentTokenId = value
		}
	},
	actions: {
		async createSession({commit}, session) {
			const res = await PaymentService.CreateSession(session);
			if (res) {
			  commit('setSession', res)
			  return true;
			} else {
			  return false;
			}
		  },

		  async cancelSession({}, sessionId) {
			const res = await PaymentService.CancelSession(sessionId);
			return res;
		  },
	}
};
